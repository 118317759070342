import React from 'react'
import Container from '@material-ui/core/Container'

import Layout from '../components/layout'
import SEO from '../components/seo'

const ContactFailedPage = () => (
  <Layout>
    <SEO title="Contact Form Send Failed" />
    <Container component="main" maxWidth="md">
      <h1>Oops</h1>
      <p>We failed to send the contact us email .</p>
    </Container>
  </Layout>
)

export default ContactFailedPage
